import { Preference } from "../../api/user-trade-preference";
import dayjs from 'dayjs';
import { BrokerAccount } from "../../brokers/entities/broker-account.entity";
import { SelectedBroker } from "../../brokers/repos/selected-broker-repo";
import { CryptoAmountUnit } from "../../api/enums";

export enum SpendLimitType {
  MaxAmount = 'max_amount',
  DefaultQuantity = 'default_quantity'
}

export const spendLimitTypeLabels: Record<SpendLimitType, string> = {
  [SpendLimitType.MaxAmount]: 'Max Amount',
  [SpendLimitType.DefaultQuantity]: 'Fix Quantity',
};

export type SpendLimit = {
  type: SpendLimitType;
  value: number | null;
};

export type CryptoQuantityPreference = {
  unit: CryptoAmountUnit
  quantity: number | null
}

export type PreferenceFormValues = Omit<Preference, 'crypto_quantity' | 'crypto_unit' | 'max_amount' | 'default_quantity' | 'manual_expiry_date' | 'broker_account' | 'broker_account_paper'> & {
  spend_limit: SpendLimit
  crypto_quantity_preference: CryptoQuantityPreference
  manual_expiry_date: dayjs.Dayjs | null
  broker: SelectedBroker | null,
}