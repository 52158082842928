import { BrokerAccount } from "../brokers/entities/broker-account.entity";
import axios from "./axios";
import { MyCandeFiMembership } from "./candefi-memberships";
import { UserRole } from "./enums";
import { SignalProviderWithMembershipPlans } from "./signal-providers";
import { StripeAccount } from "./stripe-account";
import { GenericStatusResponse } from "./types";
import { Preference } from "./user-trade-preference";

export interface LoginRequest {
  email: string
  password: string
}

export interface LoginResponse {
  access_token: string
  refresh_token: string
  access_token_expires: string // iso8601 datetime
  refresh_token_expires: string // iso8601 datetime
}

export interface SignupRequest {
  name: string
  email: string
  password: string
  username: string
  referring_signal_provider_username: string | null
  referral_code: string | null
  role: UserRole
}

export interface SendPasswordResetOTPRequest {
  email: string
}

export interface VerifyPasswordResetOTPRequest {
  email: string
  otp: string
}

export interface VerifyPasswordResetOTPResponse {
  token: string
}

export interface PasswordResetRequest {
  token: string
  password: string
}

export interface CurrentUserInfo {
  id: number
  name: string
  email: string
  role: UserRole
  username: string | null
  coin_balance: number
  monthly_coins: number
  stripe_account: StripeAccount | null
  broker_accounts: BrokerAccount[]
  trade_preference: Preference | null
  signal_providers: SignalProviderWithMembershipPlans[]
  email_verified_at: string | null
  candefi_membership: MyCandeFiMembership | null
  total_coins: number
}

export type UserPublicRead = {
  name: string
  username: string
}

const loginApi = {
  async login(request : LoginRequest) {
    const { data } = await axios.post<LoginResponse>('/auth/login', request);
    return data;
  },

  async signup(request : SignupRequest) {
    const { data } = await axios.post<GenericStatusResponse>('/auth/signup', request);
    return data;
  },

  async sendPasswordResetOtp(request: SendPasswordResetOTPRequest) {
    const { data } = await axios.post<GenericStatusResponse>('/auth/password-reset/send-otp', request);
    return data;
  },

  async verifyPasswordResetOtp(request: VerifyPasswordResetOTPRequest) {
    const { data } = await axios.post<VerifyPasswordResetOTPResponse>('/auth/password-reset/verify-otp', request);
    return data;
  },

  async resetPassword(request: PasswordResetRequest) {
    const { data } = await axios.post<GenericStatusResponse>('/auth/password-reset', request);
    return data;
  },

  async me() {
    const { data } = await axios.get<CurrentUserInfo>('/auth/me');
    return data;
  },

  async logout() {
    const { data } = await axios.delete<GenericStatusResponse>('/auth/logout');
    return data;
  },

  async sendVerificationEmail(email: string) {
    const { data } = await axios.post<GenericStatusResponse>('/auth/email-verification/send', {
      email
    });
    return data;
  },

  async verifyEmail(email: string, otp: string) {
    const { data } = await axios.post<LoginResponse>('/auth/email-verification/verify', {
      email,
      otp
    });
    return data;
  }
}

export default loginApi;