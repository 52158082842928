import { CryptoAmountUnit, Direction, OptionsExpiryPreference, OrderType, StrikePreference, TimeInForce } from "./enums";
import axios from "./axios"
import { GenericIDResponse } from "./types";
import { BrokerAccount } from "../brokers/entities/broker-account.entity";

export interface Preference {
  default_quantity: number | null;
  max_amount: number | null;
  stop_loss: number;
  take_profit: number;
  trailing_stop: number;
  time_in_force: TimeInForce
  strike_preference: StrikePreference | null;
  expiry_preference: OptionsExpiryPreference | null;
  order_type_preference: OrderType | null;
  options_direction_preference: Direction | null;
  manual_expiry_date: string | null
  broker_account: BrokerAccount | null
  broker_account_paper: boolean
  crypto_unit: CryptoAmountUnit | null
  crypto_quantity: number | null
}

export type EditTradePreferenceRequest = Omit<Preference, 'broker_account' | 'broker_account_paper'> & {
  broker_account_id: number | null,
  broker_account_paper: boolean,
}

const tradePreferenceApi = { 
  async editTradePreference(request: EditTradePreferenceRequest){
    const {data} = await axios.patch<GenericIDResponse>("/trade-preference", request)
    return data
  },
}

export default tradePreferenceApi;