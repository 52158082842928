import { Card } from "antd";
import { RequiresBroker } from "../../brokers/components/RequiresBroker";
import { SelectBrokerDropdown } from "../../brokers/components/SelectBrokerDropdown";
import { QuickTrade, QuickTradeTabKeys } from "../../trade/components/QuickTrade";
import AccountDetails from "../../brokers/components/AccountDetails";
import styles from '../../dashboard/pages/dashboard.module.scss';
import { WatchlistItemContextProvider } from "../../watchlist/context/watchlist-item-context";
import { Watchlist } from "../../watchlist/components/Watchlist";
import { CollapsibleSection } from "../../dashboard/components/CollapsibleSection";
import { ShareTradeCard } from "../../dashboard/components/ShareTradeCard";
import { ChatWidgetNew } from "../../chat/ui/chat-widget-new";
import { useAuthUser } from "../../auth/hooks/useAuthUser";
import { AffiliateAlert } from "../../affiliate/AffiliateAlert";
import { BotsSection } from "../../autotrade/components/bots/bots-section";
import { AccountBalanceCard } from "../../dashboard/components/AccountBalanceCard";
import { PositionsAndOrdersCard } from "../../dashboard/components/PositionsAndOrdersCard";
import { AutotradeAlertsTable } from "../components/alerts/alerts-table";
import { ResetWebullPaperAccountButton } from "../../brokers/components/ResetWebullPaperAccountButton";
import { TransacionsCard } from "../../dashboard/components/TransactionsCard";
import { SignalParserCard } from "../../dashboard/components/SignalParserCard";
import { WatchlistContextProvider } from "../../watchlist/context/watchlist-context";

export const AutotradePage = () => {
  const { user } = useAuthUser();
  const showWatchlist = !!user;

  return (
    <RequiresBroker>
      <WatchlistContextProvider>
        <WatchlistItemContextProvider>
          <div className={`${styles.container}`}>
      
            <div className={`${styles.row}`}>

              {
                showWatchlist && <div className={`${styles.watchlistRow}`}>
                  <Card bodyStyle={{ padding: 0 }} style={{ padding: 0 }}>
                    <Watchlist layout="horizontal"/>
                  </Card>
                </div>
              }

              <div className={`${styles.contentColumn}`}>
                <AffiliateAlert />
                <div className={`${styles.balanceAndBrokerRow}`}>
                  <AccountBalanceCard />
                  <Card 
                    style={{ display: 'flex', alignItems: 'center' }}
                    bodyStyle={{ paddingLeft: 0, paddingRight: 0, gap: 4 }}
                  >
                    <SelectBrokerDropdown />
                    <ResetWebullPaperAccountButton style={{ marginLeft: 16 }} />
                  </Card>
                </div>
                <CollapsibleSection title="Bots">
                  <BotsSection />
                </CollapsibleSection>
                <CollapsibleSection title="AutoTrade Alerts" >
                  <AutotradeAlertsTable />
                </CollapsibleSection>
                {/* <CollapsibleSection defaultOpen={false} title="AI TradeSync">
                  <SignalParserCard />
                </CollapsibleSection> */}
                <CollapsibleSection defaultOpen={false} title="Positions & Orders">
                  <PositionsAndOrdersCard />
                </CollapsibleSection>
                <CollapsibleSection title="Transactions">
                  <TransacionsCard />
                </CollapsibleSection>
              </div>

              <div className={`${styles.sidebarColumn}`}>
                <ShareTradeCard />
                <QuickTrade
                  syncWithWatchlist={true}
                  title="Smart Trader"
                />
                <CollapsibleSection title="Chat">
                    <ChatWidgetNew />
                </CollapsibleSection>
                <CollapsibleSection title="Account Details">
                  <AccountDetails />
                </CollapsibleSection>
              </div>

              {
                showWatchlist && <div className={`${styles.watchlistColumn}`}>
                  <Card bodyStyle={{ padding: 0 }} style={{ padding: 0 }}>
                    <Watchlist layout="vertical"/>
                  </Card>
                </div>
              }
            </div>
          </div>
        </WatchlistItemContextProvider>
      </WatchlistContextProvider>
    </RequiresBroker>
  );
};