import { directionLabelsShort, sideLabels } from "../../api/enum-labels";
import { AssetType, CryptoAmountUnit, OrderType, TrailType } from "../../api/enums";
import { SignalItem } from "../../api/signals";
import { formatDate, formatMoneyAuto, formatNumberAuto, formatUTCDate } from "../../shared/utils";
import { SignalOverrides } from "../types";
import { PlaceOptionsOrderRequest, PlaceStocksOrderRequest } from '../../api/orders';
import { OptionFormInitialValues } from "../../trade/hooks/useOptionsForm";
import { StockFormInitialValues } from "../../trade/hooks/useStocksForm";
import { PlaceOrderFormInitialValues } from "../../trade/types/place-order-form-initial-values";
import { BasicChatroomMessageSignal } from "../../api/chat";
import { Preference } from "../../api/user-trade-preference";
import { stocksGetQuantity } from "../../trade/utils/stocks";
import { optionsGetQuantity } from "../../trade/utils/options";
import { isCryptoSignalResponse, isOptionsSignalResponse, isStocksSignalResponse, ParseSignalResponse } from "../../api/discord-signal-parser";
import { CryptoFormInitialValues } from "../../trade/hooks/useCryptoForm";
import { displayCryptoSymbol } from "../../trade/utils/crypto";

export const formatSignalSymbol = (signal: SignalItem) => {
  if (signal.asset_type === AssetType.Stocks) {
    return signal.stock;
  }
  if (signal.asset_type === AssetType.Options) {
    const stock = signal.stock;
    const strike = formatNumberAuto(signal.strike_price!);
    const direction = directionLabelsShort[signal.direction!];
    const expiration = formatUTCDate(signal.expiration_date!, 'MM/dd');
    return `${stock} ${strike}${direction} ${expiration}`;
  }
  if (signal.asset_type === AssetType.Crypto) {
    return displayCryptoSymbol(signal.stock || '')
  }
  return '';
}

/**
 * Merges signal with overrides
 * @param signal 
 * @param overrides 
 * @returns 
 */
export const overrideSignal = (signal : SignalItem, overrides: SignalOverrides) => {
  const signalCopy : SignalItem = JSON.parse(JSON.stringify(signal));

  const isCrypto = signal.asset_type === AssetType.Crypto;

  // limit price
  signalCopy.limit_price = overrides.price || signalCopy.limit_price || signalCopy.last_price;
  signalCopy.crypto_limit_price = overrides.price || signalCopy.crypto_limit_price;

  if (isCrypto) {
    signalCopy.crypto_amount_unit = signalCopy.crypto_amount_unit || CryptoAmountUnit.Coin
  }

  // Market orders where price is specified should be limit orders
  if (!isCrypto && signalCopy.limit_price && signalCopy.order_type === OrderType.Market) {
    signalCopy.order_type = OrderType.Limit;
  }
  if (isCrypto && signalCopy.crypto_limit_price && signalCopy.order_type === OrderType.Market) {
    signalCopy.order_type = OrderType.Limit;
  }

  // Limit orders where price is not specified should be market orders
  if (!isCrypto && !signalCopy.limit_price && signalCopy.order_type === OrderType.Limit) {
    signalCopy.order_type = OrderType.Market;
  }
  console.log(isCrypto, signalCopy.crypto_limit_price, signalCopy.order_type === OrderType.Limit);
  if (isCrypto && !signalCopy.crypto_limit_price && signalCopy.order_type === OrderType.Limit) {
    console.log("this is trueeee")
    signalCopy.order_type = OrderType.Market;
  }

  // crypto quantity
  signalCopy.crypto_amount = overrides.quantity || signalCopy.crypto_amount;

  // non crypto quantity
  signalCopy.quantity = overrides.quantity || signalCopy.quantity;

  // tif
  signalCopy.time_in_force = overrides.tif || signalCopy.time_in_force;

  console.log(signalCopy);

  return signalCopy;
}

export const toPlaceOptionsOrderRequest = (signal : SignalItem) => {
  if (signal.asset_type !== AssetType.Options) {
    return null;
  }

  let limitPrice = signal.limit_price || signal.last_price || undefined;

  // if market order has limit price
  // order type should be limit
  let orderType = signal.order_type || OrderType.Limit;
  if (limitPrice && orderType === OrderType.Market) {
    orderType = OrderType.Limit
  }

  const request : PlaceOptionsOrderRequest = {
    direction: signal.direction!,
    expiration_date: signal.expiration_date!,
    order_type: orderType,
    quantity: signal.quantity!,
    side: signal.side!,
    strike_price: signal.strike_price!.toString(),
    ticker_symbol: signal.stock!,
    time_in_force: signal.time_in_force!,
    limit_price: limitPrice ? limitPrice.toString() : undefined,
    stop_price: signal.stop_price ? signal.stop_price.toString() : undefined,
  }
  return request;
}


export const toPlaceStocksOrderRequest = (signal: SignalItem) => {
  if (signal.asset_type !== AssetType.Stocks) {
    return null;
  }

  let limitPrice = signal.limit_price || signal.last_price || undefined;

  // if market order has limit price
  // order type should be limit
  let orderType = signal.order_type || OrderType.Limit;
  if (limitPrice && orderType === OrderType.Market) {
    orderType = OrderType.Limit
  }



  const request : PlaceStocksOrderRequest = {
    order_type: orderType,
    quantity: signal.quantity!,
    side: signal.side!,
    ticker_symbol: signal.stock!,
    time_in_force: signal.time_in_force!,
    limit_price: limitPrice ? limitPrice.toString() : undefined,
    stop_price: signal.stop_price ? signal.stop_price.toString() : undefined,
    trail_type: signal.trail_type ? signal.trail_type : undefined,
    trail_value: signal.trail_value ? signal.trail_value.toString() : undefined,
    ouside_regular_trading_hour: false,
  }
  return request;
}


export const toPlaceOrderFormValues = (signal: SignalItem): PlaceOrderFormInitialValues | null => {

  if (signal.asset_type === AssetType.Stocks) {

    let limitPrice = signal.limit_price || signal.last_price || undefined;

    // if market order has limit price
    // order type should be limit
    let orderType = signal.order_type || OrderType.Limit;
    if (limitPrice && orderType === OrderType.Market) {
      orderType = OrderType.Limit
    }
  

    const initialValues: StockFormInitialValues = {
      asset_type: AssetType.Stocks,
      stock: signal.stock!,
      side: signal.side!,
      limitPrice: limitPrice,
      orderType: orderType,
      quantity: signal.quantity || undefined,
      tif: signal.time_in_force || undefined,
    };
    return initialValues;
  }

  if (signal.asset_type === AssetType.Options) {
    let limitPrice = signal.limit_price || signal.last_price || undefined;

    // if market order has limit price
    // order type should be limit
    let orderType = signal.order_type || OrderType.Limit;
    if (limitPrice && orderType === OrderType.Market) {
      orderType = OrderType.Limit
    }

    const initialValues: OptionFormInitialValues = {
      asset_type: AssetType.Options,
      direction: signal.direction!,
      quantity: signal.quantity || undefined,
      side: signal.side!,
      stock: signal.stock!,
      orderType: orderType,
      expirationDate: formatDate(signal.expiration_date!, "yyyy-MM-dd"),
      strikePrice: signal.strike_price!,
      limitPrice: limitPrice,
    };
    return initialValues;
  }

  if (signal.asset_type === AssetType.Crypto) {

    let limitPrice = signal.crypto_limit_price || signal.last_price || undefined;

    // if market order has limit price
    // order type should be limit
    let orderType = signal.order_type || OrderType.Limit;
    if (limitPrice && orderType === OrderType.Market) {
      orderType = OrderType.Limit
    }
  
    const initialValues: CryptoFormInitialValues = {
      asset_type: AssetType.Crypto,
      symbol: signal.stock!,
      side: signal.side!,
      limitPrice: limitPrice,
      orderType: orderType,
      amount: signal.crypto_amount || undefined,
      amountUnit: signal.crypto_amount_unit || undefined,
      tif: signal.time_in_force || undefined,
    };

    return initialValues;
  }
  return null;
};

export function formatChatSignal(signal: BasicChatroomMessageSignal) {
  if (signal.asset_type === AssetType.Options) {
    const stock = signal.stock;
    const strike = formatNumberAuto(signal.strike_price!);
    const direction = directionLabelsShort[signal.direction!];
    const expiration = formatUTCDate(signal.expiration_date!, 'MM/dd');
    const side = sideLabels[signal.side!];
    const pricePortion = formatStockOrOptionPricePortion(signal);
    return `${side} ${stock} ${strike}${direction} ${expiration} ${pricePortion}`;
  }
  if (signal.asset_type === AssetType.Stocks) {
    const pricePortion = formatStockOrOptionPricePortion(signal);
    const stock = signal.stock;
    const side = sideLabels[signal.side!];
    return `${side} ${stock} ${pricePortion}`;
  }
  if (signal.asset_type === AssetType.Crypto) {
    const pricePortion = formatCryptoPricePortion(signal);
    const stock = signal.stock;
    const side = sideLabels[signal.side!];
    return `${side} ${stock} ${pricePortion}`;
  }
  return '';
}

function formatStockOrOptionPricePortion(signal: BasicChatroomMessageSignal) {
  let pricePortion = '';
  if (signal.order_type === OrderType.Market) {
    pricePortion += `@ MKT`;
  }
  if (signal.order_type === OrderType.Limit 
    && !isNaN(Number(signal.limit_price))
  ) {
    pricePortion += `LMT @ ${formatMoneyAuto(signal.limit_price!)}`
  }
  if (signal.order_type === OrderType.Stop && !isNaN(Number(signal.stop_price))) {
    pricePortion += `STP @ ${formatMoneyAuto(signal.stop_price!)}`
  }
  if (signal.order_type === OrderType.StopLimit 
    &&  !isNaN(Number(signal.limit_price)) 
    &&  !isNaN(Number(signal.stop_price))
  ) {
    pricePortion += `LMT @ ${formatMoneyAuto(signal.limit_price!)}`
    pricePortion += ` STP @ ${formatMoneyAuto(signal.stop_price!)}`
  }
  if (signal.order_type === OrderType.StopTrail 
    &&  signal.trail_type !== null 
    && !isNaN(Number(signal.trail_value))
  ) {
    pricePortion += `TRA STP ${ signal.trail_type === TrailType.Dollar ? formatMoneyAuto(signal.trail_value!) : Number(signal.trail_value).toFixed(2) + ' %' }`
  }
  return pricePortion;
} 

function formatCryptoPricePortion(signal: BasicChatroomMessageSignal) {
  let pricePortion = '';
  if (signal.order_type === OrderType.Market) {
    pricePortion += `@ MKT`;
  }
  if (signal.order_type === OrderType.Limit 
    && signal.crypto_limit_price 
    && !isNaN(Number(signal.crypto_limit_price))
  ) {
    pricePortion += `LMT @ ${formatMoneyAuto(signal.crypto_limit_price!)}`
  }
  if (signal.order_type === OrderType.Stop 
    && signal.crypto_stop_price
    && !isNaN(Number(signal.crypto_stop_price))) {
    pricePortion += `STP @ ${formatMoneyAuto(signal.crypto_stop_price!)}`
  }
  if (signal.order_type === OrderType.StopLimit 
    &&  signal.crypto_limit_price
    &&  signal.crypto_stop_price
    &&  !isNaN(Number(signal.crypto_limit_price)) 
    &&  !isNaN(Number(signal.crypto_stop_price))
  ) {
    pricePortion += `LMT @ ${formatMoneyAuto(signal.crypto_limit_price!)}`
    pricePortion += ` STP @ ${formatMoneyAuto(signal.crypto_stop_price!)}`
  }
  return pricePortion;
}

export function messageSignalToAppSignal(signal: BasicChatroomMessageSignal) : SignalItem {
  const { created_at, updated_at, ...rest } = signal;
  return {
    ...rest,
    created_at: new Date(signal.created_at).toISOString(),
    updated_at:  new Date(signal.updated_at).toISOString(),
  }
}

export function getMaxQuantityForSignal(signal : SignalItem, tradePreference?: Preference | null) {
  if (!tradePreference) {
    return 1;
  }
  if (tradePreference.default_quantity !== null) {
    return tradePreference.default_quantity;
  }

  if (signal.limit_price === null) {
    return 1;
  }

  if (tradePreference.max_amount !== null) {
    const isStocks = signal.asset_type === AssetType.Stocks;
    const isOptions = signal.asset_type === AssetType.Options;
    const budget = tradePreference.max_amount;
    if (isStocks) {
      return stocksGetQuantity(signal.limit_price, budget)
    }
    if (isOptions) {
      return optionsGetQuantity(signal.limit_price, budget);
    }
  }

  return 1
}

export const parsedDiscordSignalToFormInitivalValues = (response: ParseSignalResponse) : PlaceOrderFormInitialValues | null => {


  if (isStocksSignalResponse(response)) {
    const initialValues: StockFormInitialValues = {
      asset_type: AssetType.Stocks,
      stock: response.symbol!,
      limitPrice: response.price || undefined,
      orderType: response.order_type || undefined,
      side: response?.side || undefined,
      stopPrice: response?.stop || undefined,
      quantity: response?.quantity || undefined,
    };
    return initialValues;
  }

  if (isOptionsSignalResponse(response)) {
    const initialValues: OptionFormInitialValues = {
        asset_type: AssetType.Options,
        stock: response.symbol,
        orderType: response.order_type || undefined,
        direction: response.direction || undefined,
        limitPrice: response.price || undefined,
        strikePrice: response.strike || undefined,
        expirationDate: response.exp_date || undefined,
        side: response?.side || undefined,
        stopPrice: response?.stop || undefined,
        quantity: response?.quantity || undefined,
    };
    return initialValues;
  }

  if (isCryptoSignalResponse(response)) {
    const initialValues: CryptoFormInitialValues = {
        asset_type: AssetType.Crypto,
        symbol: response.symbol!,
        limitPrice: response.price || undefined,
        orderType: response.order_type || undefined,
        side: response?.side || undefined,
        stopPrice: response?.stop || undefined,
        amountUnit: response?.quantity ? CryptoAmountUnit.Coin : CryptoAmountUnit.USD,
        amount: response?.quantity || undefined,
    };
    return initialValues;
  }

  return null;
}