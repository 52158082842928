import auth from './auth';
import webullConnect from './webull-connect';
import tickerSearch from './ticker-search';
import optionsChain from './options-chain';
import orders from './orders';
import positions from './positions';
import account from './account-info';
import signals from './signals';
import signalProviders from './signal-providers';
import membership from './membership';
import tradePreference from './user-trade-preference';
import membershipPlans from './membership-plans';
import rechargesApi from './recharges';
import watchlist from './watchlist';
import cent from './cent';
import stripeConnect from './stripe-connect';
import contactInquiry from './contact-inquiry';
import analystSignupRequest from './analyst-signup-request';
import robinhoodConnect from './robinhood-connect';
import chat from './chat';
import imageUpload from './image-upload';
import affiliate from './affiliate';
import stocksQuote from './stocks-quote';
import subscribers from './subscribers';
import analystEarnings from './analyst-earnings';
import miscConfig from './misc-config';
import referrals from './referrals';
import autotradeBots from './autotrade-bots';
import autotradePreference from './autotrade-preference';
import autotradeAlerts from './autotrade-alerts';
import autotradeCentToken from './autotrade-cent-token';
import webullPaperAccount from './webull-paper-account';
import candefiMemberships from './candefi-memberships';
import fees from './fees';
import impersonate from './impersonate';
import users from './users';
import discordSignalParser from './discord-signal-parser';
import webullPayConnect from './webull-pay-connect';
import cryptoQuote from './crypto-quote';
import watchlistV2 from './watchlist-v2';

const api = {
  auth,
  webullConnect,
  tickerSearch,
  optionsChain,
  orders,
  positions,
  account,
  signals,
  signalProviders,
  membership,
  tradePreference,
  membershipPlans,
  rechargesApi,
  watchlist,
  cent,
  stripeConnect,
  contactInquiry,
  analystSignupRequest,
  robinhoodConnect,
  chat,
  imageUpload,
  affiliate,
  stocksQuote,
  subscribers,
  analystEarnings,
  miscConfig,
  referrals,
  autotradeBots,
  autotradePreference,
  autotradeAlerts,
  autotradeCentToken,
  webullPaperAccount,
  candefiMemberships,
  fees,
  impersonate,
  users,
  discordSignalParser,
  webullPayConnect,
  cryptoQuote,
  watchlistV2
}

export default api;